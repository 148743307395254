import axios, { AxiosError } from 'axios';
import clsx, { ClassValue } from 'clsx';
import isArray from 'lodash/isArray';
import { twMerge } from 'tailwind-merge';
import config from '@/fixtures/config';
import { IData } from '@/interfaces';

export function cn(...values: ClassValue[]) {
  return twMerge(clsx(...values));
}

export function timestampToDate(timestamp: string) {
  return new Date(timestamp).toLocaleString();
}

export const humanReadableFormatter = Intl.NumberFormat('en', { notation: 'compact' });

function parseValue(value: string) {
  try {
    if (!value.startsWith('[')) return value;
    return JSON.parse(value.replaceAll("'", '"'));
  } catch {
    return value;
  }
}

export function formatArrayToString(value: string | number | string[]) {
  const parsedValue = parseValue(value.toString());
  if (!isArray(parsedValue)) return parsedValue;
  // Convert ['Test','Another'] => Test, Another
  return parsedValue.join(', ');
}

export function generateBaseBackendUrl(url: string) {
  return config.baseBackendUrl + url;
}

type THasMore = {
  total: number;
  page: number;
  perPage: number;
};

export function hasMore({ total, page, perPage }: THasMore) {
  return total > page * perPage;
}

export function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export function getNextPageParam(lastData: IData<unknown>) {
  return hasMore({ ...lastData, perPage: lastData.size }) ? lastData.page + 1 : undefined;
}

export function splitFirstWord(text: string) {
  const textSplitted = text.split(' ');
  return [textSplitted[0], textSplitted.slice(1).join(' ')] as const;
}

export function download(data: any, fileName: string) {
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  URL.revokeObjectURL(url);
}

export async function copyTextToClipboard(text: string) {
  await navigator.clipboard.writeText(text);
}

export function isAxiosError<ResponseType>(
  error: unknown,
  statusCode?: number,
): error is AxiosError<ResponseType> {
  return axios.isAxiosError(error) && statusCode ? error.response?.status === statusCode : true;
}

export function conditionedTooltip(tooltips: { condition: boolean; text: string }[]) {
  const result = tooltips.find((tooltip) => tooltip.condition);
  return result?.text;
}

export function convertNullToEmptyString<T extends object>(obj: T): T {
  return Object.entries(obj).reduce((newObject, [key, value]) => {
    newObject[key] = value === null ? '' : value;
    return newObject;
  }, {} as any);
}

export const alphabetsArray = [...Array(26)].map((_, i) => String.fromCharCode(i + 65));
