import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Formik, Form as FormikForm } from 'formik';
import compact from 'lodash/compact';
import * as Yup from 'yup';
import { defaultParams } from '@/fixtures';
import commonFields from '@/fixtures/commonFields';
import validations from '@/fixtures/validations';
import { UserRole } from '@/generated';
import { ISelectField } from '@/interfaces';
import useGetClients from '@/services/clients';
import useGetUsers from '@/services/users';
import { TUserCreate } from '@/services/users/create';
import ButtonActions from '@/shared/custom/ButtonActions';
import Head from '@/shared/custom/Head';
import HeadingWithLinks from '@/shared/custom/HeadingWithLinks';
import { Fields } from '@/shared/form';
import useAuthState from '@/states/authState';
import { TField } from '@/types';

type FormProps = {
  title: string;
  onSubmit: (values: TUserCreate) => void;
  initialValues: TUserCreate;
  isSubmitting: boolean;
  showPasswords: boolean;
};

export const userInfoFields: TField[] = [
  {
    isRequired: true,
    type: 'input',
    name: 'firstName',
    label: (t) => t('First Name'),
    fieldProps: { type: 'text' },
  },
  {
    isRequired: true,
    type: 'input',
    name: 'lastName',
    label: (t) => t('Last Name'),
    fieldProps: { type: 'text' },
  },
  {
    isRequired: true,
    type: 'input',
    name: 'email',
    label: (t) => t('Email'),
    fieldProps: { type: 'email' },
  },
  {
    isRequired: false,
    type: 'input',
    name: 'phoneNumber',
    label: (t) => t('Phone Number'),
    fieldProps: { type: 'tel' },
  },
  {
    isRequired: false,
    type: 'input',
    name: 'address',
    label: (t) => t('Address'),
    fieldProps: { type: 'text' },
  },
];

export default function Form({
  title,
  onSubmit,
  showPasswords,
  initialValues,
  isSubmitting,
}: FormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authUser = useAuthState((state) => state.user!);
  const { data: users } = useGetUsers({
    ...defaultParams,
    size: 50,
    userRole: [UserRole.ADMIN],
  });
  const { data: clients } = useGetClients(
    { ...defaultParams, size: 50 },
    authUser?.userAccess.viewUserPageWithClient,
  );

  const roles: UserRole[] = [UserRole.SUPERUSER, UserRole.CLIENT, UserRole.ADMIN, UserRole.USER];
  const userRoleIndex = roles.findIndex((role) => role === authUser.userRole);
  // removing the authUser role from the list of roles
  // for example if the authUser is a client, then the list of roles will be:
  // ['ADMIN', 'USER']
  const roleOptions = roles.slice(userRoleIndex + 1).map((role) => ({
    label: role,
    value: role,
  }));

  const roleField: ISelectField<string> = {
    isRequired: true,
    type: 'select',
    name: 'userRole',
    label: t('Role'),
    fieldProps: {
      options: roleOptions,
    },
  };

  const adminField: ISelectField = {
    isRequired: true,
    type: 'select',
    name: 'adminId',
    label: t('Admin'),
    fieldProps: {
      options: users?.items.map(({ firstName, lastName, id }) => ({
        label: `${firstName} ${lastName}`,
        value: id,
      })),
    },
  };

  const clientField: ISelectField = {
    isRequired: true,
    type: 'select',
    name: 'clientId',
    label: t('Client'),
    fieldProps: {
      options: clients?.items.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    },
  };

  const validationSchema = Yup.object().shape({
    firstName: validations.name,
    lastName: validations.name,
    email: validations.email,
    address: validations.description,
    phoneNumber: validations.phoneNumber(t),
    password: Yup.string()
      .nullable()
      .when('firstName', {
        is: () => showPasswords,
        then: validations.password,
      }),
    passwordConfirmation: Yup.string()
      .nullable()
      .when('firstName', {
        is: () => showPasswords,
        then: validations.passwordConfirmation(t),
      }),
    adminId: Yup.string().when('userRole', {
      is: (value: UserRole) => value === UserRole.USER,
      then: Yup.string().required(),
    }),
    clientId: Yup.string().when('userRole', {
      is: (value: UserRole) => value === UserRole.CLIENT,
      then: Yup.string().required(),
    }),
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        ...initialValues,
        adminId: initialValues.adminId ?? '',
        passwordConfirmation: '',
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values }) => (
        <FormikForm className="space-y-4">
          <Head title={title} description={title} />
          <HeadingWithLinks
            links={[
              { text: 'Users', to: '/users' },
              { text: title, to: '#' },
            ]}
          />
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            <Fields
              fields={compact([
                ...userInfoFields,
                authUser?.userRole !== 'ADMIN' && roleField,
                authUser?.userAccess.viewUserPageWithAdmin &&
                  values.userRole === UserRole.USER &&
                  adminField,
                authUser?.userAccess.viewUserPageWithClient &&
                  values.userRole === UserRole.CLIENT &&
                  clientField,
              ])}
            />
            {showPasswords && <Fields fields={commonFields.passwordWithConfirmation} />}
          </div>
          <ButtonActions
            submitProps={{ isLoading: isSubmitting, disabled: isSubmitting }}
            cancelProps={{ onClick: () => navigate('/users') }}
          />
        </FormikForm>
      )}
    </Formik>
  );
}
