import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@/components/resources/index/Container';
import HeadingWithCreateLink from '@/components/resources/index/heading/HeadingWithCreateLink';
import Options from '@/components/resources/index/options';
import useRecruitmentFilters, { recruitmentFilters } from '@/fixtures/resources/recruitments';
import { useTable } from '@/hooks';
import Pagination from '@/shared/custom/Pagination';
import JoinButtonFilter from '@/shared/resources/JoinButtonFilter';
import ActiveFilters from '@/shared/resources/activeFilters';
import Cards from '@/shared/views/Cards';
import useAuthState from '@/states/authState';
import useSettingsState from '@/states/settingsState';
import { TPage, TTableColumns } from '@/types';

const resource = 'recruitments';

type RendererProps = {
  title: string;
  columns: TTableColumns;
  data: TPage | undefined;
  isLoading: boolean;
};

export default function Renderer({ title, columns, data, isLoading }: RendererProps) {
  const { t } = useTranslation();
  const { visibility, includeArchived } = useSettingsState((state) => state[resource]);
  const setRecruitmentsSettings = useSettingsState((state) => state.setRecruitmentsSettings);
  const canCreateRecruitment = useAuthState((state) => state.user?.userAccess.createRecruitment);
  const filters = useRecruitmentFilters();

  const table = useTable({
    isLoading,
    columns,
    data: data?.items,
    visibility,
    onVisibilityChange: (change) => {
      const value = typeof change === 'function' ? change(visibility) : change;
      setRecruitmentsSettings({ visibility: value });
    },
  });

  return (
    <div className="flex justify-between md:space-x-4">
      <Container resource={resource}>
        <HeadingWithCreateLink
          resource={resource}
          title={title}
          createLink={`/${resource}/create`}
          create={{
            disabled: !canCreateRecruitment,
            tooltip: canCreateRecruitment
              ? undefined
              : t('You have to upgrade your plan to access this feature'),
          }}
        />
        <JoinButtonFilter resource={resource} filter={filters.at(0)!} />
        <ActiveFilters resource={resource} filters={[]} />
        <Cards isLoading={isLoading} resource={resource} table={table} />
        <Pagination resource={resource} total={data?.total} />
      </Container>
      <Options
        topChildren={
          <div className="flex items-center justify-between gap-4">
            <h3 className="text-xl font-bold">{t('Show Archives')}</h3>
            <input
              defaultChecked={includeArchived}
              onChange={(e) => setRecruitmentsSettings({ includeArchived: e.target.checked })}
              id="sortType"
              type="checkbox"
              className="toggle toggle-primary"
            />
          </div>
        }
        hasMultipleViews={false}
        resource={resource}
        table={table}
        filters={recruitmentFilters}
      />
    </div>
  );
}
